* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
}

html {
  height: 100%;
  background-repeat: no-repeat;
  background: rgb(0,78,146);
  background: -moz-radial-gradient(circle, rgba(0,78,146,1) 0%, rgba(0,4,40,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,78,146,1) 0%, rgba(0,4,40,1) 100%);
  background: radial-gradient(circle, rgba(0,78,146,1) 0%, rgba(0,4,40,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#004e92",endColorstr="#000428",GradientType=1);
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}
  
.header-container {
    padding-top: 20px;
}
  
.header {
    margin: 0;
    font-size: 100px;
    letter-spacing: .3rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    color:white;
}

.sold-out {
  margin: 0;
  font-size: 128px;
  letter-spacing: .3rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  color:white;
}
  
.button:hover {
    cursor: pointer;
}
  
.button {
    background: -webkit-linear-gradient(left, #02020A, #02020aa4);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    font-family: 'Oswald', sans-serif;
}
  
.cta-button {
    height: 6rem;
    border: 0;
    width: auto;
    padding-left:2.5rem;
    padding-right:2.5rem;
    padding-top:0px;
    margin-top:1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 2px;
    color: white;
}
  
.connect-wallet-button {
    background: -webkit-linear-gradient(left, #02020A, #02020aa4);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
}
  
.button-container {
    padding-top: 40px;
}
  
.count-button {
    height: 2.5rem;
    border: 0;
    width: auto;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top:0px;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    background: -webkit-linear-gradient(left, #02020A, #02020aa4);
    background-size: 200% 200%;
}
  
.count {
    color: white;
    font-size: 64px;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Oswald', sans-serif;
}

.description {
  color: white;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  font-size:xx-large;
}
  
  /* KeyFrames */
@-webkit-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
@-moz-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
@keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
  

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap');

@font-face {
    font-family: "adrip";
    src: local("adrip"), url("./assets/fonts/adrip1.ttf") format("truetype");
}

@media screen and (max-width: 700px) {
    body {
        font-size: 18px;
    }

    .accordion {
        width: 90%;
    }

    html {
      height: 100%;
      background-repeat: no-repeat;
      background: rgb(0,78,146);
      background: -moz-radial-gradient(circle, rgba(0,78,146,1) 0%, rgba(0,4,40,1) 100%);
      background: -webkit-radial-gradient(circle, rgba(0,78,146,1) 0%, rgba(0,4,40,1) 100%);
      background: radial-gradient(circle, rgba(0,78,146,1) 0%, rgba(0,4,40,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#004e92",endColorstr="#000428",GradientType=1);
    }

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
  }
}